import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Button,
  // Button,
  FormControlLabel,
  Grid,
  Radio,
  Tab,
  Tooltip,
  Typography,
} from '@mui/material';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import CustomCheckBox from 'components/CustomCheckBox/customCheckBox';
import CustomDatePicker from 'components/CustomDatePicker/customDatePicker';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomTextArea from 'components/CustomTextArea/customTextArea';
import { instantState } from 'hooks';
import _, { lowerCase } from 'lodash';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import CurrencyFormat from 'react-currency-format';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import {
  COMMON_ENDPOINT,
  Fill_FORM_ENDPOINT,
  LEAD_LEVEL_FORM_ENDPOINT,
} from 'utils/Constant';
import {
  alertOptions,
  checkRadioButtonEnableAns,
  validateDate,
} from 'utils/helper';
import { wizardFormSchema } from 'utils/ValidatorSchema';

type sectionApproveModalProps = {
  approveStatus: boolean;
  currentAnswer: string | any;
  mainIndex: number | null;
  question: any;
  qusIndex: number | null;
  sectionId: number | null;
  show: boolean;
};

type QuestionRes = {
  question: string;
  response_type: number;
  id: string;
  previousAnswer: string | any;
  response: string[];
  multiple_pdf_field: Array<any>;
  copyAnswer: any;
  formAnswerId: number | null;
  webUrlLInk?: string;
  is_radio_populate: boolean;
  link_code?: string;
};
type TreeResponse = {
  section_id: number;
  name: string;
  order: number;
  questionDetails: Array<QuestionRes>;
  question_id: number[];
  section_start_Count: number;
  visa_form_id: string;
  section_approve: boolean;
};
type Props = {
  focusElement?: any;
  formType: string;
  list: Array<TreeResponse>;
  tabHidden?: boolean;
  formId: number | null;
  beneficiaryID?: number;
  dependentPersonId?: number;
  contractFor?: any;
  leadId?: number;
  petitionID?: number;
  strategyId?: string;
  setHCData?: any;
  showManageOption?: boolean;
  setHistoryAdded?: any;
  hiddenMargin?: boolean;
  sectionApproveHide?: boolean;
  handleClickToScroll?: (id: string) => void;
  draftCall?: boolean;
  highlightText?: (id, value) => void;
  inputRefs?: any;
  setCurrentSelectQus?: (src: any) => void;
  hideDependentNotes?: boolean;
  callDocumentProductionAPI?: () => void;
  organizationId?: string;
  copytoMaster?: (
    question: any,
    answer: any,
    previousAnswerId: any,
    setCopyDialog: any,
    response_type: any,
  ) => void;
  copyEnable?: boolean;
  sessionUpdateFormId?: () => void;
};
export const WizardForm = React.forwardRef((props: Props, ref) => {
  const {
    focusElement = null,
    formType = '',
    list,
    tabHidden = false,
    formId,
    strategyId,
    petitionID,
    leadId,
    contractFor,
    dependentPersonId,
    beneficiaryID,
    setHCData,
    showManageOption,
    hiddenMargin = false,
    sectionApproveHide = false,
    handleClickToScroll,
    draftCall,
    highlightText,
    inputRefs,
    setCurrentSelectQus,
    hideDependentNotes,
    callDocumentProductionAPI,
    organizationId,
    copytoMaster,
    copyEnable,
    // sessionUpdateFormId,
  } = props;
  const [searchParams] = useSearchParams();
  const { editId, petitionId } = useParams();
  const [tabValue, setTabValue] = useState(1);
  const [loader, setLoader] = useState(false);
  const [copyDialog, setCopyDialog] = useState<any>({
    data: '',
    show: false,
  });
  // const [formList, setFormList] = useState<Array<TreeResponse>>([]);
  // const [formListPre, setFormListPre] = useState<Array<TreeResponse>>([]);
  const [confirmOpen, setConfirmOpen] = useState<sectionApproveModalProps>({
    approveStatus: false,
    currentAnswer: '',
    mainIndex: null,
    question: {},
    qusIndex: null,
    sectionId: null,
    show: false,
  });
  const [dirconfirmOpen, setDirConfirmOpen] = useState<any>({
    mainIndex: null,
    section_approve: false,
    section_id: null,
    show: false,
  });

  const [genderConfirmOpen, setGenderConfirmOpen] = useState<any>({
    gender: null,
    mainIndex: null,
    qusIndex: null,
    show: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sectionId, setSectionId] = useState(0);
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const { petitions }: any = useAppSelector((store: any) => store.petitionUser);
  const {
    control: control,
    getValues,
    setValue,
  } = useForm<{
    wizardFormFields: Array<TreeResponse>;
  }>({
    criteriaMode: 'all',
    defaultValues: {
      wizardFormFields: [],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(wizardFormSchema),
  });

  const {
    fields,
    append,
    update: wizardArrUpdate,
    remove: wizardArrRemove,
  } = useFieldArray({
    control: control,
    name: 'wizardFormFields',
  });

  const nextTab = () => {
    setTabValue((prev) => prev + 1);
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };
  const prevTab = () => {
    setTabValue((prev) => prev - 1);
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };
  const getCommentHistory = useCallback(
    (questionDetail, formAnswerId) => {
      if (formAnswerId) {
        const url =
          location?.pathname.includes('invited') ||
          location?.pathname.includes('associated')
            ? `${Fill_FORM_ENDPOINT}/organization/history/${String(
                formAnswerId,
              )}`
            : `${Fill_FORM_ENDPOINT}/history/${String(formAnswerId)}`;
        getAllListData(url)
          .then((hisResp: any) => {
            if (
              location?.pathname.includes('invited') ||
              location?.pathname.includes('associated')
            ) {
              setHCData((pre) => ({
                ...pre,
                comments: [],
                history: hisResp && hisResp.length ? hisResp : [],
                loading: false,
                selectedQuestion: questionDetail,
              }));
            } else {
              getAllListData(
                `${Fill_FORM_ENDPOINT}/comments/${String(formAnswerId)}`,
              )
                .then((commentResp: any) => {
                  questionDetail['formAnswerId'] = formAnswerId;
                  setHCData((pre) => ({
                    ...pre,
                    comments:
                      commentResp && commentResp.length ? commentResp : [],
                    history: hisResp && hisResp.length ? hisResp : [],
                    loading: false,
                    selectedQuestion: questionDetail,
                  }));
                })
                .catch((e: any) => {
                  setHCData((pre) => ({
                    ...pre,
                    comments: [],
                    history: [],
                    loading: false,
                  }));
                });
            }
          })
          .catch((e: any) => {
            setHCData((pre) => ({
              ...pre,
              comments: [],
              history: [],
              loading: false,
            }));
          });
      } else {
        setHCData((pre) => ({
          ...pre,
          comments: [],
          history: [],
          loading: false,
        }));
      }
    },
    [setHCData],
  );
  const handleCopyMasterData = (action: string) => {
    if (action === 'yes') {
      copytoMaster &&
        copytoMaster(
          copyDialog?.data?.previousAnswer,
          copyDialog?.data?.id,
          copyDialog?.data?.formAnswerId,
          setCopyDialog,
          copyDialog?.data?.response_type,
        );
      return;
    }
    setCopyDialog((pre) => ({
      ...pre,
      show: false,
    }));
  };
  const updateOnSelectRow = useCallback((ques, sectionId) => {
    setHCData({
      comments: [],
      history: [],
      loading: true,
      selectedQuestion: {},
    });
    if (showManageOption && ques.response_type !== 11) {
      handleClickToScroll ? handleClickToScroll(ques.id) : null;
      setSectionId(sectionId);
      const answerdQus = answerFetch({ ...ques });
      setCurrentSelectQus && setCurrentSelectQus({ ...ques }); // addOn work for pdf edit
      setHCData((pre) => ({ ...pre, selectedQuestion: { ...answerdQus } }));
      getCommentHistory(answerdQus, answerdQus.formAnswerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    childMethod() {
      // sessionUpdateFormId && sessionUpdateFormId();
    },
    async dummyMethod() {
      return await initialDummyAnswerUpdate();
    },
  }));
  const initialDummyAnswerUpdate = async () => {
    const getHCData = await instantState(setHCData);
    const sectionCopyId = await instantState(setSectionId);
    const createAnswer = mappingFields(
      [getHCData.selectedQuestion],
      sectionCopyId,
    );
    const draftAnswerObj = getFormFieldsValues(createAnswer);
    // sessionUpdateFormId && sessionUpdateFormId();
    return await saveFormValuesApi(
      draftAnswerObj,
      getHCData.selectedQuestion,
      'dummyOnly',
    );
  };

  const handleChange = (_event: any, newValue: string) => {
    const myDiv = document.getElementById('sectionDiv');
    myDiv && (myDiv.scrollTop = 0);
    setTabValue(Number(newValue));
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  const closeSectionApprove = () => {
    setConfirmOpen((pre) => ({
      ...pre,
      approveStatus: false,
      currentAnswer: '',
      mainIndex: null,
      question: {},
      qusIndex: null,
      sectionId: null,
      show: false,
    }));
  };
  const sectionApprove = async (
    sectionId,
    sectionStatus,
    mainIndex,
    isDirectApprove = false,
  ) => {
    let payload: any = {
      approved: userDetail.role_id === 3 && isDirectApprove ? true : false,
      petitionId: Number(petitionId ? petitionId : editId),
      sectionId: sectionId,
    };
    if (formType === 'dependent') {
      payload = { ...payload, dependentId: dependentPersonId };
    }
    setLoader(true);
    return await new Promise((resolve, rejcet) => {
      addNewData(payload, COMMON_ENDPOINT.SECTIONAPPROVE)
        .then((resp: any) => {
          toast.success(
            isDirectApprove
              ? 'Section approved successfully'
              : 'Section updated successfully, you can able to edit any fields in this section',
            alertOptions,
          );
          setConfirmOpen((pre) => ({ ...pre, show: false }));
          setDirConfirmOpen({
            mainIndex: null,
            section_approve: false,
            section_id: null,
            show: false,
          });
          const currentQusObj = fields[mainIndex];
          wizardArrUpdate(mainIndex, {
            ...currentQusObj,
            section_approve: payload.approved,
          });
          setLoader(false);
          resolve(resp);
        })
        .catch((err) => {
          setLoader(false);
          rejcet(err);
          // toast.error('Oops!!! Something went wrong', alertOptions);
        });
    });
  };
  const sectionApproveWithForm = async (action, type = 'default') => {
    try {
      if (action === 'yes') {
        if (type === 'default') {
          const confirmOpen: sectionApproveModalProps = await instantState(
            setConfirmOpen,
          );
          await sectionApprove(
            confirmOpen.sectionId,
            confirmOpen.approveStatus,
            confirmOpen.mainIndex,
          );
        } else {
          await instantState(setDirConfirmOpen);
          await sectionApprove(
            dirconfirmOpen.section_id,
            true,
            dirconfirmOpen.mainIndex,
            true,
          );
        }
      }
    } catch (error) {
      setLoader(false);
    }
  };
  const answerFetch = (qus: QuestionRes) => {
    if (qus.response_type !== 13) {
      if (
        (qus.response_type === 7 || qus.response_type === 15) &&
        qus.previousAnswer
      ) {
        qus.previousAnswer = new Date(qus.previousAnswer);
      }
    } else {
      try {
        const arr = JSON.parse(qus.previousAnswer);
        if (arr.length) {
          qus.previousAnswer = arr;
        } else {
          qus.previousAnswer = [''];
        }
      } catch (error) {
        qus.previousAnswer = [''];
      }
    }
    qus.copyAnswer = qus.previousAnswer;
    return qus;
  };
  const orderRearrange = (treeArr) => {
    if (treeArr && treeArr.length) {
      const mappedArr = treeArr.map((val, index) => {
        const qusOrder = [];
        if (focusElement) {
          if (val.section_id === focusElement.sectionId) {
            setTabValue(Number(index) + 1);
          }
        }

        if (val.question_id && val.question_id.length) {
          val.question_id.map((qusId) => {
            const searchQus = val.questionDetails.find(
              (qusFullView) => qusFullView.id === qusId,
            );
            if (searchQus) {
              const answerdQus = answerFetch(searchQus);
              qusOrder.push(answerdQus);
            }
          });
          val.questionDetails = qusOrder;
        }
        append(fetchSubmittedAns(val));
        return fetchSubmittedAns(val);
      });
      return mappedArr;
    }
    return [];
  };
  const fetchSubmittedAns = (qus) => {
    if (qus.questionDetails && qus.questionDetails.length) {
      qus.questionDetails.map((val) => {
        if (formType === 'caseplan') {
          if (val && val.previousAnswer) {
            try {
              const obj = JSON.parse(val.previousAnswer);
              val.previousAnswer = obj && obj.answer ? obj.answer : '';
            } catch (error) {
              return false;
            }
          }
        }
        if (val.response_type === 13) {
          val.arrayList = [];
        }
        if (
          (formType === 'configureForms' ||
            formType === 'dependent' ||
            formType === 'strategy' ||
            formType === 'lead') &&
          (val.response_type === 5 || val.response_type === 6) &&
          // eslint-disable-next-line no-prototype-builtins
          val.hasOwnProperty('multiple_pdf_field')
        ) {
          try {
            const arr = JSON.parse(val.previousAnswer);
            val.multiple_pdf_field.map((mapfield) => {
              const checkInd = arr.findIndex(
                (pdf) =>
                  pdf.response.toLowerCase() ===
                  mapfield.response.toLowerCase(),
              );
              mapfield.status = arr[checkInd].status;
              return mapfield;
            });
            // return true;
          } catch (error) {
            return false;
          }
        }
        return val;
      });
    }
    return qus;
  };
  const add = (mainIndex: number, qusIndex: number) => {
    const currentQusObj = fields[mainIndex];
    wizardArrUpdate(mainIndex, {
      ...currentQusObj,
      questionDetails: currentQusObj.questionDetails.map((q, qI) => {
        if (qI === qusIndex) {
          q.previousAnswer = Array.isArray(q.previousAnswer)
            ? q.previousAnswer.concat([''])
            : [''];
        }
        return q;
      }),
    });
  };
  const remove = (
    mainIndex: number,
    qusIndex: number,
    removeTextAreaIndex: number,
  ) => {
    const currentQusObj = fields[mainIndex];
    wizardArrUpdate(mainIndex, {
      ...currentQusObj,
      questionDetails: [
        ...currentQusObj.questionDetails.map((q, qI) => {
          if (qI === qusIndex) {
            const ans = q.previousAnswer;
            q.previousAnswer = null;
            q.previousAnswer = [
              ...(Array.isArray(ans)
                ? ans.filter(
                    (text, textIndex) => textIndex !== removeTextAreaIndex,
                  )
                : ['']),
            ];
          }
          return { ...q };
        }),
      ],
    });
  };
  const checkAns = (checkBoxValue: string, ans: any) => {
    if (
      formType === 'configureForms' ||
      formType === 'dependent' ||
      formType === 'strategy' ||
      formType === 'lead'
    ) {
      try {
        const arr = JSON.parse(ans);
        const checkInd = arr.findIndex(
          (pdf) => pdf.response.toLowerCase() === checkBoxValue.toLowerCase(),
        );
        if (checkInd !== -1) {
          return arr[checkInd].status;
        }
      } catch (error) {
        return false;
      }
    } else {
      if (ans) {
        const changeArr = ans.split(',');
        return changeArr.includes(checkBoxValue);
      } else {
        return false;
      }
    }
  };

  const checkedOptionVal = (
    e: any,
    checkedOption: string,
    qus: QuestionRes,
    mainIndex: number,
    qusIndex: number,
  ) => {
    if (
      formType === 'configureForms' ||
      formType === 'dependent' ||
      formType === 'strategy' ||
      formType === 'lead'
    ) {
      if (
        qus &&
        // eslint-disable-next-line no-prototype-builtins
        qus.hasOwnProperty('multiple_pdf_field') &&
        qus.multiple_pdf_field.length
      ) {
        const mutiPdfField = qus.multiple_pdf_field.map((val) => {
          if (val.response.toLowerCase() === checkedOption.toLowerCase()) {
            val.status = e.target.checked;
          } else if (
            val.response.toLowerCase() !== checkedOption.toLowerCase() &&
            qus.response_type === 5
          ) {
            val.status = false;
          }
          return val;
        });
        qus.multiple_pdf_field = mutiPdfField;
        return JSON.stringify(mutiPdfField);
      }
    } else {
      let arr = qus.previousAnswer ? qus.previousAnswer.split(',') : [];
      if (e.target.checked) {
        if (qus.response_type === 5) {
          arr = [checkedOption];
        } else {
          if (!arr.includes(checkedOption)) {
            arr.push(checkedOption);
          }
        }
      } else {
        const index = arr.indexOf(checkedOption);
        arr.splice(index, 1);
      }
      const finalArr = [];
      arr.map((val) => {
        if (val) {
          finalArr.push(val);
        }
      });
      return finalArr;
    }
  };

  const genderFillFormCall = async (action) => {
    try {
      if (action === 'yes') {
        const genderConfirmOpen = await instantState(setGenderConfirmOpen);
        await updateData(
          String(beneficiaryID),
          { gender: genderConfirmOpen.gender },
          Fill_FORM_ENDPOINT,
        )
          .then(() => {
            setGenderConfirmOpen({
              gender: null,
              mainIndex: null,
              qusIndex: null,
              show: false,
            });
            callDocumentProductionAPI();
          })
          .catch((e) => {
            setLoader(false);
          });
      } else {
        setValue(
          `wizardFormFields.${Number(
            genderConfirmOpen.mainIndex,
          )}.questionDetails.${Number(
            genderConfirmOpen.qusIndex,
          )}.previousAnswer`,
          null,
        );
        setGenderConfirmOpen({
          gender: null,
          mainIndex: null,
          qusIndex: null,
          show: false,
        });

        return;
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const saveAnswer = (
    questionDetails: Array<QuestionRes>,
    sectionId: number,
    section_approve: boolean,
    mainIndex: number,
    qusIndex: number,
    radioMultiPopulate = false,
  ) => {
    if (section_approve) {
      return;
    }
    if (
      questionDetails &&
      questionDetails.length &&
      (questionDetails[0].response_type === 1 ||
        questionDetails[0].response_type === 2 ||
        questionDetails[0].response_type === 3 ||
        questionDetails[0].response_type === 10 ||
        questionDetails[0].response_type === 12 ||
        questionDetails[0].response_type === 14) &&
      (questionDetails[0].copyAnswer
        ? String(questionDetails[0].copyAnswer).trim()
        : '') ===
        (questionDetails[0].previousAnswer
          ? String(questionDetails[0].previousAnswer).trim()
          : '')
    ) {
      return;
    }
    if (
      questionDetails &&
      questionDetails.length &&
      [7, 15, 1, 2, 3, 10, 12, 14, 4, 13, 5, 8, 6].includes(
        questionDetails[0].response_type,
      )
    ) {
      const currentQusObj = fields[mainIndex];
      wizardArrUpdate(mainIndex, {
        ...currentQusObj,
        questionDetails: [
          ...currentQusObj.questionDetails.map((q, qI) => {
            if (qI === qusIndex) {
              q = {
                ...q,
                copyAnswer: questionDetails[0].previousAnswer,
                ...([7, 15, 1, 2, 3, 10, 12, 14, 4, 13, 5, 8, 6].includes(
                  questionDetails[0].response_type,
                )
                  ? { previousAnswer: questionDetails[0].previousAnswer }
                  : {}),
              };
            }
            return { ...q };
          }),
        ],
      });
    }

    const createAnswer = mappingFields(
      questionDetails,
      sectionId,
      radioMultiPopulate,
    );
    const draftAnswerObj = getFormFieldsValues(createAnswer);
    // sessionUpdateFormId && sessionUpdateFormId();

    if (
      questionDetails[0].response_type === 4 &&
      questionDetails[0].link_code === 'beneficiary_document_gender'
    ) {
      setGenderConfirmOpen({
        gender: lowerCase(draftAnswerObj.list[0].answer),
        mainIndex: mainIndex,
        qusIndex: qusIndex,
        show: true,
      });
    } else {
      saveFormValuesApi(draftAnswerObj, questionDetails[0]);
    }
  };

  const saveFormValuesApi = async (
    draftAnswerObj: any,
    question,
    dummyOnly?: any,
  ) => {
    try {
      if (highlightText && draftCall) {
        highlightText(
          draftAnswerObj.list[0].questionId,
          draftAnswerObj.list[0].answer,
        );
      } else {
        const answerRes = await addNewData(
          draftAnswerObj,
          searchParams.get('leadUser') &&
            userDetail.role_id === 4 &&
            searchParams.get('type') !== 'petition'
            ? LEAD_LEVEL_FORM_ENDPOINT
            : location?.pathname.includes('invited') ||
              location?.pathname.includes('associated')
            ? `${Fill_FORM_ENDPOINT}/organization`
            : Fill_FORM_ENDPOINT,
        );

        if (answerRes?.message === 'Answer has been added') {
          highlightText
            ? highlightText(
                draftAnswerObj.list[0].questionId,
                draftAnswerObj.list[0].answer,
              )
            : null;
          const fields = getValues('wizardFormFields');

          // readip button cross population
          if (
            answerRes?.radioResponse &&
            Array.isArray(answerRes?.radioResponse) &&
            answerRes?.radioResponse.length
          ) {
            radioBtnCrossPopulate(answerRes?.radioResponse);
          }
          // commn same qiestion present in another section where update on the value
          fields
            .filter(
              (e, cIndex) =>
                cIndex !== Number(tabValue) - 1 &&
                e.questionDetails &&
                Array.isArray(e.questionDetails) &&
                e.questionDetails.some((q) => question.id === q.id),
            )
            .map((fieldVal) =>
              wizardArrUpdate(
                fields.findIndex((c) => c.section_id === fieldVal.section_id),
                {
                  ...fieldVal,
                  questionDetails: [
                    ...fieldVal.questionDetails.map((q) => {
                      if (q.id === question.id) {
                        q = {
                          ...q,
                          copyAnswer: question.previousAnswer,
                          ...(answerRes?.history?.form_answer_id
                            ? { formAnswerId: answerRes.history.form_answer_id }
                            : {}),
                          ...([
                            7, 15, 1, 2, 3, 10, 12, 14, 4, 13, 5, 8, 6,
                          ].includes(question.response_type)
                            ? {
                                previousAnswer: question.previousAnswer,
                              }
                            : {}),
                        };
                      }
                      return { ...q };
                    }),
                  ],
                },
              ),
            );
          // EMployee location update
          if (
            answerRes &&
            answerRes?.employmentLocation &&
            answerRes?.employmentLocation?.questionId &&
            answerRes?.employmentLocation?.answer
          ) {
            const employQus = fields.filter(
              (e) =>
                e.questionDetails &&
                Array.isArray(e.questionDetails) &&
                e.questionDetails.some(
                  (q) => q.id === answerRes?.employmentLocation?.questionId,
                ),
            );
            if (employQus && employQus.length) {
              wizardArrUpdate(
                fields.findIndex(
                  (c) => c.section_id === employQus[0].section_id,
                ),
                {
                  ...employQus[0],
                  questionDetails: employQus[0].questionDetails.map((q) => {
                    if (q.id === answerRes?.employmentLocation?.questionId) {
                      q.copyAnswer = answerRes?.employmentLocation?.answer;
                      q.previousAnswer = answerRes?.employmentLocation?.answer;
                    }
                    return { ...q };
                  }),
                },
              );
            }
          }

          // form anser Id updated
          if (answerRes?.history?.form_answer_id) {
            const currentQusObj = fields[Number(tabValue) - 1];
            const qusestionInfo = currentQusObj.questionDetails.find(
              (obj) => obj.id === question.id,
            );
            if (qusestionInfo && !qusestionInfo.formAnswerId) {
              wizardArrUpdate(Number(tabValue) - 1, {
                ...currentQusObj,
                questionDetails: [
                  ...currentQusObj.questionDetails.map((q, qI) => {
                    if (q.id === qusestionInfo.id) {
                      q.formAnswerId = answerRes.history.form_answer_id;
                    }
                    return { ...q };
                  }),
                ],
              });
            }
            getCommentHistory(question, answerRes.history.form_answer_id);
          }
        }
        return answerRes;
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const radioBtnCrossPopulate = (relatedQus: Array<any>) => {
    fields
      .filter((e) =>
        e.questionDetails.some((q) =>
          relatedQus.find((rlq) => rlq.questionId === q.id),
        ),
      )
      .map((fieldVal) => {
        wizardArrUpdate(
          fields.findIndex((c) => c.section_id === fieldVal.section_id),
          {
            ...fieldVal,
            questionDetails: [
              ...fieldVal.questionDetails.map((q) => {
                let copyqus = { ...q };
                const question = relatedQus.find(
                  (rlq) => rlq.questionId === q.id,
                );
                if (question) {
                  copyqus = {
                    ...q,
                    copyAnswer: question.response,
                    ...(question?.form_answer_id
                      ? { formAnswerId: question?.form_answer_id }
                      : {}),
                    previousAnswer: question.response,
                  };
                }
                return { ...copyqus };
              }),
            ],
          },
        );
        return fieldVal;
      });
  };
  const onSubmitForm = (e: any) => {
    e.preventDefault();
    e.target[0].blur();
  };
  const mappingFields = (
    ques: Array<QuestionRes>,
    sectionIdNo: number,
    radioMultiPopulate = false,
  ) => {
    const answerFields = ques.map((val) => {
      let Answerval = '';

      if (formType === 'caseplan') {
        Answerval = JSON.stringify({
          answer: val.previousAnswer || '',
          question: val.question,
        });
      } else {
        if (
          (formType === 'configureForms' ||
            formType === 'dependent' ||
            formType === 'strategy' ||
            formType === 'lead') &&
          (val.response_type === 5 || val.response_type === 6)
        ) {
          // eslint-disable-next-line no-prototype-builtins
          Answerval = val.hasOwnProperty('multiple_pdf_field')
            ? val.previousAnswer
            : JSON.stringify([]);
        } else if (val.response_type === 13) {
          Answerval = val.previousAnswer
            ? JSON.stringify(val.previousAnswer)
            : JSON.stringify(['']);
        } else if (val.response_type === 7 || val.response_type === 15) {
          Answerval = dateFormateChange(val.previousAnswer);
        } else {
          Answerval = val.previousAnswer || '';
        }
      }
      const obj = {
        answer: Answerval,
        formId: formId ? formId : sectionIdNo,
        questionId: val.id,
        sectionId: sectionIdNo,
        ...(radioMultiPopulate ? { isRadioEnable: true } : {}),
      };
      return obj;
    });
    return answerFields;
  };
  const dateFormateChange = (ans: any) => {
    if (ans && ans !== 'Invalid Date') {
      const dateFormte = new Date(ans);
      if (dateFormte) {
        const date = dateFormte.getDate();
        const month = dateFormte.getMonth() + 1;
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return `${month < 10 ? '0' + month : month}/${
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          date < 10 ? '0' + date : date
        }/${dateFormte.getFullYear()}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getFormFieldsValues = (createAnswer) => {
    if (
      searchParams.get('leadUser') &&
      userDetail.role_id === 4 &&
      searchParams.get('type') !== 'petition'
    ) {
      return {
        answer:
          createAnswer && createAnswer.length && createAnswer[0]
            ? createAnswer[0].answer
            : '',
        beneficiaryId: beneficiaryID,
        leadId: Number(searchParams.get('leadUser')),
        questionId:
          createAnswer && createAnswer.length && createAnswer[0]
            ? createAnswer[0].questionId
            : '',
      };
    }
    if (
      location?.pathname.includes('invited') ||
      location?.pathname.includes('associated')
    ) {
      return {
        firmId: userDetail.companyId,
        list: createAnswer,
        organizationAssociated: location?.pathname.includes('invited')
          ? false
          : true,
        organizationId: organizationId,
        strategyId: strategyId ? strategyId : null,
      };
    }
    return {
      benificieryId: beneficiaryID,
      dependentUserId: dependentPersonId ? dependentPersonId : null,
      formType: formType === 'caseplan' ? 'case' : formType,
      leadFor: contractFor ? contractFor : null,
      leadId: leadId ? leadId : null,
      list: createAnswer,
      petitionId: petitionID,
      strategyId: strategyId ? strategyId : null,
    };
  };

  useEffect(() => {
    wizardArrRemove();
    setTimeout(() => {
      orderRearrange(list);
    }, 100);

    // if (finalList && finalList.length) {
    // setFormList(finalList);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      <Box
        className="wizardTab tabWidth"
        sx={{ position: 'relative', width: '100%', zIndex: '2' }}>
        {loader ? <div className="liberty-loader" /> : ''}
        {list.length > 0 ? (
          fields && fields.length > 0 ? (
            <TabContext value={String(tabValue)}>
              {!tabHidden && (
                <Typography
                  component={'div'}
                  className="sticky-tab1 d-flex-a d-flex-sb">
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    variant="scrollable"
                    // className="sticky-tab1"
                    scrollButtons={'auto'}
                    allowScrollButtonsMobile={true}>
                    {fields.map((part, i) => (
                      <Tab
                        label={part.name}
                        value={String(i + 1)}
                        key={i}
                        className={
                          part.section_approve ? 'section-approve' : ''
                        }
                      />
                    ))}
                  </TabList>
                  <>
                    {fields.map((part, mainIndex) => (
                      // eslint-disable-next-line react/jsx-key, react/jsx-no-useless-fragment
                      <>
                        {part.questionDetails &&
                        part.questionDetails.length > 0 &&
                        Number(tabValue) - 1 === mainIndex ? (
                          // eslint-disable-next-line react/jsx-no-useless-fragment
                          <>
                            {userDetail.role_id === 3 &&
                            !sectionApproveHide &&
                            !part.section_approve &&
                            (formType === 'configureForms' ||
                              formType === 'dependent') &&
                            !petitions?.petitionData?.isAffilateCase ? (
                              <Typography
                                component={'div'}
                                className="d-flex flex-end">
                                <Button
                                  className="n-add-btn"
                                  sx={{ height: '44px !important' }}
                                  onClick={() =>
                                    setDirConfirmOpen({
                                      mainIndex,
                                      section_approve: part.section_approve,
                                      section_id: part.section_id,
                                      show: true,
                                    })
                                  }>
                                  Approve
                                </Button>
                              </Typography>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    ))}
                  </>
                </Typography>
              )}

              <Typography
                component={'div'}
                id="sectionDiv"
                sx={
                  hiddenMargin
                    ? {}
                    : formType === 'configureForms'
                    ? {
                        marginLeft: '2rem !important',
                        marginRight: '2rem !important',
                        marginTop: '1rem !important',
                      }
                    : { marginTop: '1rem !important' }
                }>
                {fields.map((part, mainIndex) => (
                  <TabPanel value={String(mainIndex + 1)} key={mainIndex}>
                    {part.questionDetails &&
                    part.questionDetails.length > 0 &&
                    Number(tabValue) - 1 === mainIndex ? (
                      <>
                        {part.questionDetails.map((ques, qusIndex) => (
                          <Grid container key={qusIndex}>
                            <Grid item md={12} sm={12}>
                              <form onSubmit={onSubmitForm} autoComplete="on">
                                <Typography
                                  component={'div'}
                                  className="custom-field"
                                  // onClick={(e) => {
                                  //   if (
                                  //     showManageOption &&
                                  //     ques.response_type !== 11 &&
                                  //     formType !== 'contract'
                                  //   ) {
                                  //     updateOnSelectRow(ques, part.section_id);
                                  //   }
                                  // }}
                                >
                                  <Typography
                                    component={'div'}
                                    className={
                                      'f-15 font-bold mb-1 d-flex d-flex-sb translate'
                                    }
                                    sx={
                                      ques.response_type === 11
                                        ? {
                                            backgroundColor: '#f1f1f1',
                                            padding: '5px 0px 5px 0px',
                                          }
                                        : {
                                            color:
                                              // ques.response_type === 7
                                              //   ? ques.previousAnswer &&
                                              //     ques.previousAnswer ==
                                              //       'Invalid Date'
                                              //     ? '#ff0000'
                                              //     : ''
                                              //   : !ques.previousAnswer
                                              //   ? '#ff0000'
                                              //   : '',
                                              String(
                                                ques.previousAnswer,
                                              ).trim() !== ''
                                                ? ''
                                                : '#ff0000',
                                          }
                                    }>
                                    {!ques.webUrlLInk ? (
                                      `${1 + Number(qusIndex)}. ${String(
                                        ques?.question,
                                      )}`
                                    ) : (
                                      <a
                                        href={ques.webUrlLInk}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ color: '#007bff' }}>
                                        {`${1 + Number(qusIndex)}. ${String(
                                          ques?.question,
                                        )}`}
                                      </a>
                                    )}
                                    {ques.previousAnswer && copyEnable ? (
                                      <Typography
                                        component={'div'}
                                        className={'d-flex-ja'}>
                                        <ContentCopyIcon
                                          onClick={() =>
                                            setCopyDialog((pre) => ({
                                              ...pre,
                                              data: ques,
                                              show: true,
                                            }))
                                          }
                                        />
                                      </Typography>
                                    ) : (
                                      ''
                                    )}
                                  </Typography>

                                  <Typography
                                    component={'div'}
                                    className="mb-1">
                                    {/* Text type & Additional SysyetemFields */}
                                    {[1, 10].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomInput
                                              autoComplete={true}
                                              focus={
                                                focusElement
                                                  ? Number(
                                                      focusElement.questionId,
                                                    ) === Number(ques.id)
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              className={
                                                inputRefs
                                                  ? 'custom-input1 doctype'
                                                  : 'custom-input1'
                                              }
                                              placeHolder={''}
                                              id={ques.id}
                                              name={name}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              value={value}
                                              onChange={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      e.target.value,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                              }}
                                              onBlur={(e) => {
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* MultiLine TextBox */}
                                    {[2].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomTextArea
                                              focus={
                                                focusElement
                                                  ? Number(
                                                      focusElement.questionId,
                                                    ) === Number(ques.id)
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              className={
                                                inputRefs
                                                  ? 'custom-textarea2 doctype'
                                                  : 'custom-textarea2'
                                              }
                                              placeHolder={''}
                                              id={ques.id}
                                              name={name}
                                              value={value}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              onChange={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      e.target.value,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                              }}
                                              onBlur={(e) => {
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* Number type */}
                                    {[3].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomInput
                                              focus={
                                                focusElement
                                                  ? Number(
                                                      focusElement.questionId,
                                                    ) === Number(ques.id)
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              className={
                                                inputRefs
                                                  ? 'custom-input1 doctype'
                                                  : 'custom-input1'
                                              }
                                              placeHolder={''}
                                              type={'number'}
                                              id={ques.id}
                                              name={name}
                                              value={value}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              onChange={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      e.target.value,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                              }}
                                              onBlur={(e) =>
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                )
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* Dropdown type */}
                                    {[4].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomSelect
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              id={ques.id}
                                              searchable={true}
                                              labelKey={'label'}
                                              valueKey={'value'}
                                              name={name}
                                              options={
                                                ques.response &&
                                                ques.response.length
                                                  ? ques.response.map((e) => ({
                                                      label: e,
                                                      value: e,
                                                    }))
                                                  : []
                                              }
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              value={value}
                                              onChange={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      e.target.value,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                              placeHolder={'Select'}
                                              className={
                                                inputRefs
                                                  ? 'custom-search-input bo-rles doctype'
                                                  : 'custom-search-input bo-rles'
                                              }
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* Radio type */}
                                    {[5].includes(ques.response_type) &&
                                      ques.response &&
                                      ques.response.length &&
                                      ques.response.map(
                                        (checkVal, checkBoxIndex) => (
                                          <Typography
                                            component={'div'}
                                            key={checkBoxIndex}>
                                            <Typography
                                              component={'div'}
                                              className="rodio-pad">
                                              <FormControlLabel
                                                id={ques.id}
                                                value={checkVal}
                                                name={ques.id}
                                                checked={checkAns(
                                                  checkVal,
                                                  ques.previousAnswer,
                                                )}
                                                onFocus={() => {
                                                  updateOnSelectRow(
                                                    ques,
                                                    part.section_id,
                                                  );
                                                }}
                                                control={<Radio />}
                                                onChange={(event: any) => {
                                                  if (part.section_approve) {
                                                    setConfirmOpen({
                                                      approveStatus: false,
                                                      currentAnswer:
                                                        event.target.checked,
                                                      mainIndex: mainIndex,
                                                      question: { ...ques },
                                                      qusIndex: qusIndex,
                                                      sectionId:
                                                        part.section_id,
                                                      show: true,
                                                    });
                                                    return;
                                                  }
                                                  const values =
                                                    checkedOptionVal(
                                                      event,
                                                      checkVal,
                                                      ques,
                                                      mainIndex,
                                                      qusIndex,
                                                    );
                                                  saveAnswer(
                                                    [
                                                      {
                                                        ...ques,
                                                        previousAnswer: values,
                                                      },
                                                    ],
                                                    part.section_id,
                                                    part.section_approve,
                                                    mainIndex,
                                                    qusIndex,
                                                    ques?.is_radio_populate,
                                                  );
                                                }}
                                                // disabled={inputDisable}
                                                label={checkVal}
                                              />
                                            </Typography>
                                            {ques.response.length - 1 ===
                                              checkBoxIndex &&
                                              checkRadioButtonEnableAns(
                                                ques.previousAnswer,
                                              ) && (
                                                <Typography
                                                  className="mt-1 n-add-btn"
                                                  component={'span'}
                                                  sx={{
                                                    border: 'unset !important',
                                                    borderRadius:
                                                      '5px !important',
                                                    cursor: 'pointer',
                                                    fontFamily:
                                                      'Arial-Regular !important',
                                                    height: '35px !important',
                                                    minWidth:
                                                      '100px !important',
                                                    padding: '8px',
                                                    position: 'relative',
                                                    top: '5px',
                                                    width: '100px !important',
                                                  }}
                                                  onClick={() => {
                                                    if (part.section_approve) {
                                                      setConfirmOpen({
                                                        approveStatus: false,
                                                        currentAnswer: false,
                                                        mainIndex: mainIndex,
                                                        question: { ...ques },
                                                        qusIndex: qusIndex,
                                                        sectionId:
                                                          part.section_id,
                                                        show: true,
                                                      });
                                                      return;
                                                    }
                                                    const values =
                                                      checkedOptionVal(
                                                        {
                                                          target: {
                                                            checked: false,
                                                          },
                                                        },
                                                        '',
                                                        ques,
                                                        mainIndex,
                                                        qusIndex,
                                                      );
                                                    saveAnswer(
                                                      [
                                                        {
                                                          ...ques,
                                                          previousAnswer:
                                                            values,
                                                        },
                                                      ],
                                                      part.section_id,
                                                      part.section_approve,
                                                      mainIndex,
                                                      qusIndex,
                                                      ques?.is_radio_populate,
                                                    );
                                                  }}>
                                                  Reset
                                                </Typography>
                                              )}
                                          </Typography>
                                        ),
                                      )}
                                    {/* checkbox type */}
                                    {[6].includes(ques.response_type) &&
                                      ques.response &&
                                      ques.response.length &&
                                      ques.response.map(
                                        (checkVal, checkBoxIndex) => (
                                          <Typography
                                            key={checkBoxIndex}
                                            component={'div'}>
                                            <CustomCheckBox
                                              id={ques.id}
                                              name={checkVal}
                                              checked={checkAns(
                                                checkVal,
                                                ques.previousAnswer,
                                              )}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              value={checkVal}
                                              onChange={(event) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      event.target.checked,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                const values = checkedOptionVal(
                                                  event,
                                                  checkVal,
                                                  ques,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer: values,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                              // disabled={inputDisable}
                                            />
                                          </Typography>
                                        ),
                                      )}
                                    {/* Date type */}
                                    {[7, 15].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomDatePicker
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              isInputRef={true}
                                              className={
                                                inputRefs
                                                  ? 'wizard-datepiker doctype'
                                                  : 'wizard-datepiker'
                                              }
                                              name={name}
                                              value={value}
                                              id={ques.id}
                                              isCompleteOnchange={true}
                                              // minDate={
                                              //   ques.previousAnswer
                                              //     ? ques.previousAnswer
                                              //     : new Date()
                                              // }
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              onBlur={(e) => {
                                                const previousAnswer =
                                                  ques.previousAnswer
                                                    ? moment(
                                                        ques.previousAnswer,
                                                      ).format('MM/DD/YYYY')
                                                    : '';
                                                if (
                                                  e.target.value !==
                                                  previousAnswer
                                                ) {
                                                  if (
                                                    e.target.value &&
                                                    !validateDate(
                                                      e.target.value,
                                                    )
                                                  ) {
                                                    document
                                                      .getElementById(ques.id)
                                                      .classList.add('error');
                                                  } else {
                                                    if (
                                                      document
                                                        .getElementById(ques.id)
                                                        .classList.contains(
                                                          'error',
                                                        )
                                                    ) {
                                                      const qIDClass =
                                                        document.getElementById(
                                                          ques.id,
                                                        );
                                                      if (
                                                        qIDClass.classList.contains(
                                                          'error',
                                                        )
                                                      ) {
                                                        qIDClass.classList.remove(
                                                          'error',
                                                        );
                                                      }
                                                    }
                                                    saveAnswer(
                                                      [
                                                        {
                                                          ...ques,
                                                          previousAnswer:
                                                            e.target.value,
                                                        },
                                                      ],
                                                      part.section_id,
                                                      part.section_approve,
                                                      mainIndex,
                                                      qusIndex,
                                                    );
                                                  }
                                                }
                                              }}
                                              onChangeDate={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer: e,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                                const qIDClass =
                                                  document.getElementById(
                                                    ques.id,
                                                  );
                                                if (
                                                  qIDClass.classList.contains(
                                                    'error',
                                                  )
                                                ) {
                                                  qIDClass.classList.remove(
                                                    'error',
                                                  );
                                                }

                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer: e,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* Time type */}
                                    {[8].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CustomInput
                                              className={
                                                inputRefs
                                                  ? 'custom-input1 doctype'
                                                  : 'custom-input1'
                                              }
                                              placeHolder=""
                                              type={'time'}
                                              id={ques.id}
                                              name={name}
                                              value={value}
                                              onChange={(e) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      e.target.value,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(e);
                                              }}
                                              onBlur={(e) =>
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                )
                                              }
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* File Upload */}
                                    {[9].includes(ques.response_type) && (
                                      <div>
                                        File Upload (Comming Soon.....){' '}
                                      </div>
                                    )}
                                    {/* Label type */}
                                    {[11].includes(ques.response_type) && ''}
                                    {/* Currency Formate */}
                                    {[12].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <CurrencyFormat
                                              id={ques.id}
                                              ref={
                                                inputRefs
                                                  ? inputRefs[ques.id]
                                                  : { ...ref }
                                              }
                                              className={
                                                inputRefs
                                                  ? 'custom-input1 doctype'
                                                  : 'custom-input1'
                                              }
                                              style={{
                                                borderColor: '#d7d7d7',
                                                borderRadius: '5px',
                                                color: '#333333',
                                                fontSize: '14px',
                                                outline: 'unset',
                                                padding: '6.2px 14px',
                                              }}
                                              name={name}
                                              value={value ? value : ''}
                                              thousandSeparator={true}
                                              prefix={'$'}
                                              onValueChange={(values) => {
                                                const { value: currencyVal } =
                                                  values;
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer: currencyVal,
                                                    mainIndex: mainIndex,
                                                    question: { ...ques },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                onChange(currencyVal);
                                                // const { value } = values;
                                                // formattedValue = $2,223
                                                // value ie, 2223
                                                // updateValue(
                                                //   mainIndex,
                                                //   qusIndex,
                                                //   value,
                                                // );
                                              }}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              onBlur={(e: any) => {
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        e.target.value &&
                                                        String(
                                                          e.target.value,
                                                        ).trim()
                                                          ? e.target.value.split(
                                                              '$',
                                                            )[1]
                                                          : '',
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                    {/* List response Additional */}
                                    {[13].includes(ques.response_type) && (
                                      <>
                                        {ques.previousAnswer &&
                                          Array.isArray(ques.previousAnswer) &&
                                          ques.previousAnswer.map(
                                            (e: string, index: number) => (
                                              <div key={index}>
                                                {ques.previousAnswer.length >
                                                  1 && (
                                                  <CloseIcon
                                                    sx={{
                                                      cursor: 'pointer',
                                                      fill: 'gray',
                                                      height: '25px',
                                                      width: '25px',
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        part.section_approve
                                                      ) {
                                                        setConfirmOpen({
                                                          approveStatus: false,
                                                          currentAnswer: '',
                                                          mainIndex: mainIndex,
                                                          question: {
                                                            ...ques,
                                                          },
                                                          qusIndex: qusIndex,
                                                          sectionId:
                                                            part.section_id,
                                                          show: true,
                                                        });
                                                        return;
                                                      }

                                                      remove(
                                                        mainIndex,
                                                        qusIndex,
                                                        index,
                                                      );
                                                      const existingvalue =
                                                        ques.copyAnswer[index];
                                                      if (
                                                        existingvalue !==
                                                        undefined
                                                      ) {
                                                        saveAnswer(
                                                          [
                                                            {
                                                              ...ques,
                                                            },
                                                          ],
                                                          part.section_id,
                                                          part.section_approve,
                                                          mainIndex,
                                                          qusIndex,
                                                        );
                                                      }
                                                    }}
                                                  />
                                                )}
                                                <Typography
                                                  component={'div'}
                                                  className="uadd-title"
                                                  sx={{
                                                    paddingBottom:
                                                      'unset !important',
                                                    paddingTop:
                                                      'unset !important',
                                                  }}>
                                                  <Controller
                                                    control={control}
                                                    name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer.${index}`}
                                                    render={({
                                                      field: {
                                                        onChange,
                                                        value,
                                                        name,
                                                        ref,
                                                      },
                                                      formState,
                                                    }) => {
                                                      return (
                                                        <CustomTextArea
                                                          id={ques.id}
                                                          ref={
                                                            inputRefs
                                                              ? inputRefs[
                                                                  ques.id
                                                                ]
                                                              : { ...ref }
                                                          }
                                                          className={
                                                            inputRefs
                                                              ? 'custom-textarea2 doctype'
                                                              : 'custom-textarea2'
                                                          }
                                                          name={name}
                                                          value={
                                                            fields[mainIndex]
                                                              .questionDetails[
                                                              qusIndex
                                                            ].previousAnswer[
                                                              index
                                                            ]
                                                          }
                                                          extraStyle={{
                                                            width: '100%',
                                                          }}
                                                          onFocus={() => {
                                                            updateOnSelectRow(
                                                              ques,
                                                              part.section_id,
                                                            );
                                                          }}
                                                          onChange={(e) => {
                                                            if (
                                                              part.section_approve
                                                            ) {
                                                              setConfirmOpen({
                                                                approveStatus:
                                                                  false,
                                                                currentAnswer:
                                                                  e.target
                                                                    .value,
                                                                mainIndex:
                                                                  mainIndex,
                                                                question: {
                                                                  ...ques,
                                                                },
                                                                qusIndex:
                                                                  qusIndex,
                                                                sectionId:
                                                                  part.section_id,
                                                                show: true,
                                                              });
                                                              return;
                                                            }

                                                            onChange(e);
                                                          }}
                                                          onBlur={(e) => {
                                                            const existingvalue =
                                                              ques.copyAnswer[
                                                                index
                                                              ];
                                                            if (
                                                              (!existingvalue &&
                                                                !String(e)
                                                                  .toLowerCase()
                                                                  .trim()) ||
                                                              String(e)
                                                                .toLowerCase()
                                                                .trim() ===
                                                                String(
                                                                  existingvalue,
                                                                ).toLowerCase()
                                                            ) {
                                                              return;
                                                            }

                                                            if (
                                                              !ques.previousAnswer.includes(
                                                                existingvalue,
                                                              )
                                                            ) {
                                                              saveAnswer(
                                                                [
                                                                  {
                                                                    ...ques,
                                                                    previousAnswer:
                                                                      ques.previousAnswer.map(
                                                                        (
                                                                          ans,
                                                                          ansIndex,
                                                                        ) => {
                                                                          if (
                                                                            ansIndex ===
                                                                            index
                                                                          ) {
                                                                            ans =
                                                                              e
                                                                                .target
                                                                                .value;
                                                                          }
                                                                          return ans;
                                                                        },
                                                                      ),
                                                                  },
                                                                ],
                                                                part.section_id,
                                                                part.section_approve,
                                                                mainIndex,
                                                                qusIndex,
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      );
                                                    }}
                                                  />
                                                </Typography>
                                              </div>
                                            ),
                                          )}
                                        {true && (
                                          <AddIcon
                                            sx={{
                                              cursor: 'pointer',
                                              fill: 'gray',
                                              height: '25px',
                                              width: '25px',
                                            }}
                                            onClick={() => {
                                              if (part.section_approve) {
                                                setConfirmOpen({
                                                  approveStatus: false,
                                                  currentAnswer: '',
                                                  mainIndex: mainIndex,
                                                  question: {
                                                    ...ques,
                                                  },
                                                  qusIndex: qusIndex,
                                                  sectionId: part.section_id,
                                                  show: true,
                                                });
                                                return;
                                              }
                                              add(mainIndex, qusIndex);
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* Phone Number */}
                                    {[14].includes(ques.response_type) && (
                                      <Controller
                                        control={control}
                                        defaultValue={''}
                                        name={`wizardFormFields.${mainIndex}.questionDetails.${qusIndex}.previousAnswer`}
                                        render={({
                                          field: { onChange, value, name, ref },
                                          formState,
                                        }) => {
                                          return (
                                            <PhoneInput
                                              {...ref}
                                              inputProps={{
                                                id: ques.id,
                                              }}
                                              inputStyle={{ width: '200px' }}
                                              country={'us'}
                                              value={value}
                                              onFocus={() => {
                                                updateOnSelectRow(
                                                  ques,
                                                  part.section_id,
                                                );
                                              }}
                                              onChange={(
                                                value,
                                                data: any,
                                                // event,
                                                formattedValue,
                                              ) => {
                                                if (part.section_approve) {
                                                  setConfirmOpen({
                                                    approveStatus: false,
                                                    currentAnswer:
                                                      formattedValue,
                                                    mainIndex: mainIndex,
                                                    question: {
                                                      ...ques,
                                                    },
                                                    qusIndex: qusIndex,
                                                    sectionId: part.section_id,
                                                    show: true,
                                                  });
                                                  return;
                                                }
                                                if (data.dialCode === value) {
                                                  return;
                                                }
                                                onChange(value);
                                              }}
                                              onBlur={(e, data: any) => {
                                                saveAnswer(
                                                  [
                                                    {
                                                      ...ques,
                                                      previousAnswer:
                                                        data.dialCode ===
                                                        e.target.value.replace(
                                                          /\D/g,
                                                          '',
                                                        )
                                                          ? ''
                                                          : e.target.value,
                                                    },
                                                  ],
                                                  part.section_id,
                                                  part.section_approve,
                                                  mainIndex,
                                                  qusIndex,
                                                );
                                              }}
                                            />
                                          );
                                        }}
                                      />
                                    )}
                                  </Typography>
                                </Typography>
                              </form>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    ) : null}

                    <Grid
                      container
                      // className="bottom-fixed"
                      sx={{
                        bottom: '40% !important',
                        padding: '5px 20px',
                        zIndex: 1,
                      }}>
                      <Grid item md={6} sm={12}>
                        {tabValue !== 1 ? (
                          <Typography
                            component={'div'}
                            className="d-flex-a "
                            sx={{ margin: '5px -5px' }}>
                            <Button
                              className="add-btn mr-1"
                              sx={{
                                cursor:
                                  tabValue === 1
                                    ? 'not-allowed !important'
                                    : 'pointer !important',
                                fontSize: '16px !important',
                                minWidth: '75px !important',
                              }}
                              type="button"
                              onClick={() => tabValue !== 1 && prevTab()}
                              // disabled={tabValue === 1}
                            >
                              Previous
                            </Button>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                      <Grid item md={6} sm={12}>
                        {tabValue !== fields.length ? (
                          <Typography
                            component={'div'}
                            className="d-flex-jae "
                            sx={{ margin: '5px -5px' }}>
                            <Button
                              className="add-btn"
                              sx={{
                                cursor:
                                  tabValue === fields.length
                                    ? 'not-allowed !important'
                                    : 'pointer !important',
                                fontSize: '16px !important',
                                minWidth: '75px !important',
                              }}
                              type="button"
                              onClick={() =>
                                tabValue !== fields.length && nextTab()
                              }
                              // disabled={tabValue === fields.length}
                            >
                              Next
                            </Button>
                          </Typography>
                        ) : (
                          ''
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Typography>
            </TabContext>
          ) : (
            <div className="liberty-loader" />
          )
        ) : (
          <Typography
            component={'div'}
            sx={{
              background: 'white',
              height: '80px',
              // marginTop: '1rem',
              // paddingTop: '1.8rem',
              marginTop: '-15px',
              textAlign: 'center',
              width: '100%',
            }}>
            {dependentPersonId &&
            !hideDependentNotes &&
            formType === 'dependent' &&
            userDetail.role_id === 3 ? (
              <>
                <Typography component={'div'}>
                  Select Add Form to the right under Ovie.
                </Typography>
                <Typography component={'div'}>
                  If only one beneficiary is applying as a dependent, select the
                  I-539.
                </Typography>
                <Typography component={'div'}>
                  If more than one additional dependent is applying, select
                  I-539 for the spouse and I-539A for each dependent child.
                </Typography>
              </>
            ) : (
              <Typography
                component={'div'}
                sx={{
                  padding: '20px',
                  textAlign: 'center',
                  width: '100%',
                }}>
                No Record(s) Found.
              </Typography>
            )}
          </Typography>
        )}
      </Box>
      {fields.length > 1 ? (
        <Grid
          container
          // className="bottom-fixed"
          sx={{
            bottom: '40% !important',
            padding: '5px 20px',
            position: 'sticky',
            zIndex: 1,
          }}>
          <Grid item md={6} sm={12}>
            <Typography
              component={'div'}
              className="d-flex-a "
              sx={{ margin: '5px -5px' }}>
              {tabValue !== 1 ? (
                <Tooltip title="Pervious" placement="top-start">
                  <ArrowBackIosIcon
                    onClick={() => tabValue !== 1 && prevTab()}
                    sx={{
                      cursor:
                        tabValue === 1
                          ? 'not-allowed !important'
                          : 'pointer !important',
                      height: '35px',
                      marginLeft: '-33px',
                      width: '35px',
                    }}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
          <Grid item md={6} sm={12}>
            <Typography
              component={'div'}
              className="d-flex-jae "
              sx={{ margin: '5px -5px' }}>
              {tabValue !== fields.length ? (
                <Tooltip title="Next" placement="top-start">
                  <ArrowForwardIosIcon
                    onClick={() => tabValue !== fields.length && nextTab()}
                    sx={{
                      cursor:
                        tabValue === fields.length
                          ? 'not-allowed !important'
                          : 'pointer !important',
                      height: '35px',
                      marginRight: '-42px',
                      width: '35px',
                    }}
                  />
                </Tooltip>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={confirmOpen.show}
        onConfirm={(act) =>
          act === 'yes' ? sectionApproveWithForm(act) : closeSectionApprove()
        }
        autoCloseDisable={true}>
        {
          'This Section is already approved, Are you sure want to change the approved status'
        }
      </ConfirmDialog>
      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={dirconfirmOpen.show}
        onConfirm={(act) =>
          act === 'yes'
            ? sectionApproveWithForm(act, 'direct')
            : setDirConfirmOpen(() => ({
                mainIndex: null,
                section_approve: false,
                section_id: null,
                show: false,
              }))
        }
        autoCloseDisable={true}>
        {'Are you sure want to approve this section.'}
      </ConfirmDialog>
      <ConfirmDialog
        title="CONFIRMATION"
        floatingBtnShow={true}
        open={copyDialog.show}
        close={() => {
          setCopyDialog((pre) => ({
            ...pre,
            show: false,
          }));
        }}
        onConfirm={handleCopyMasterData}
        autoCloseDisable={true}>
        {'Are you sure do you want to add this answer to master strategy ?'}
      </ConfirmDialog>
      <ConfirmDialog
        title="CONFIRM"
        floatingBtnShow={true}
        open={genderConfirmOpen.show}
        onConfirm={(act) => genderFillFormCall(act)}
        autoCloseDisable={true}>
        {`Are you sure you want to select ${String(genderConfirmOpen.gender)}`}
        {petitions?.petitionData?.benificiery_name !== '' ||
        !_.isUndefined(petitions?.petitionData?.benificiery_name)
          ? ` for ${String(petitions?.petitionData?.benificiery_name)}`
          : null}
      </ConfirmDialog>
    </>
  );
});
